var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('video',{staticStyle:{"width":"100%"},attrs:{"autoplay":"","playsinline":"","src":"/bear.mp4","controls":""}}),_c('div',{staticClass:"mb-3",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push('/event')}}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/event_main.jpg"),"alt":"썸네일"}})]),_c('br'),_c('br'),_c('br'),_vm._m(0),_vm._v(" 📣📣 보노보노 송년의밤 신청마감 📣📣"),_c('br'),_vm._m(1),_vm._v(" ! 문의사항은 인스타그램 댓글/DM 으로 남겨주세요."),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('div',{staticClass:"row justify-content-center m-0"},[_c('div',{staticClass:"col-6 mb-3",on:{"click":function($event){return _vm.$router.push('/start')}}},[_vm._m(2)]),_c('div',{staticClass:"col-6 mb-3",on:{"click":function($event){return _vm.$router.push('/goods_swimwear')}}},[_vm._m(3)]),_c('div',{staticClass:"col-6 mb-3",on:{"click":function($event){return _vm.$router.push('/goods_cap')}}},[_vm._m(4)]),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("　　＊　'　*　.★　＊　　'＊　　　*"),_c('br'),_vm._v("＊　　.　'　　+:..:+ 　　　 ' 　＊"),_c('br'),_vm._v(".　　　＊　　☆☆☆　＊　Merry '　　."),_c('br'),_vm._v("　　*　　'　+:...+....:+　　Christmas"),_c('br'),_vm._v("'　　　　'　☆☆☆☆☆　　　＊　'　　"),_c('br'),_vm._v("　＊　＊　+:...:+＠+:...:+ 　　　'　　*"),_c('br'),_vm._v("＊　.　　.☆☆☆☆☆☆☆ ＊　'　*　."),_c('br'),_vm._v("　　.　　+:..:+&+:...:+:...:+ "),_c('br'),_vm._v("　*　.　☆☆☆☆☆☆☆☆☆　＊　'　　　*"),_c('br'),_vm._v("'　.　　+:...:+♡+:...:+§+:..:+"),_c('br'),_vm._v(".　*　☆☆☆☆☆☆☆☆☆☆☆　　'　*"),_c('br'),_vm._v("　　.+:..:+♡+:..:+@+:..:+♡+:..:+"),_c('br'),_vm._v("　.　　　　　.　▨ 　 '　' ＊　　　　　*"),_c('br'),_vm._v("　　　.　*　　　　　　.　　　.　　' "),_c('br'),_vm._v("　 ,·´ ¸,·´`) "),_c('br'),_vm._v("　(¸,·´　(¸＊♥Merry Christmas &"),_c('br'),_vm._v("　　　　　　　　　　Happy New Year ♥〃´`)")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"linear-gradient(90deg, #ff9a9e, #fad0c4, #fbc2eb, #f6d365, #fdc830)","background-size":"400% 400%","animation":"gradientAnimation 6s ease infinite","margin":"10px 0","padding":"20px 10px","border-radius":"5px","color":"#000","font-weight":"bold"}},[_vm._v(" 1. 날자 : 앞으로 해도 1221 뒤로해도 1221"),_c('br'),_vm._v(" 2. 장소 : 우리 집앞 동네 말고 동래구 동래"),_c('br'),_vm._v(" 3. 최종 24인 한정판 굿즈 제공"),_c('br'),_vm._v(" 4. 드레스코드 : 블랙, 그린, 레드 3종세트 "),_c('br'),_vm._v(" 5. 파티용품 : 머리띠, 선글라스 개인지참 "),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/cls01.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2"},[_vm._v("START CLASS (종료)"),_c('br'),_vm._v("10. 27 SUN 08시 - 10시")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/goods_swimwear.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2"},[_vm._v("보노보노 수영복 오픈"),_c('br'),_c('span',{staticClass:"highlighted-text"},[_vm._v("(마감)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/goods_cap.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2"},[_vm._v("보노보노 수모 오픈"),_c('br'),_c('span',{staticClass:"highlighted-text"},[_vm._v("(마감)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 mb-3"},[_c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/cls02.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2 text-secondary"},[_vm._v("(준비중)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 mb-3"},[_c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/cls03.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2 text-secondary"},[_vm._v("(준비중)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 mb-3"},[_c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/cls04.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2 text-secondary"},[_vm._v("(준비중)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 mb-3"},[_c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/cls05.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2 text-secondary"},[_vm._v("(준비중)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 mb-3"},[_c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/cls06.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2 text-secondary"},[_vm._v("(준비중)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 mb-3"},[_c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/cls07.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2 text-secondary"},[_vm._v("(준비중)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 mb-3"},[_c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/cls08.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2 text-secondary"},[_vm._v("(준비중)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 mb-3"},[_c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/cls_etc_01.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2 text-secondary"},[_vm._v("(준비중)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 mb-3"},[_c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/cls_etc_02.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2 text-secondary"},[_vm._v("(준비중)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 mb-3"},[_c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/cls_etc_03.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2 text-secondary"},[_vm._v("(준비중)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 mb-3"},[_c('div',{staticClass:"thumbnail-box"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/image/cls_etc_04.jpg"),"alt":"썸네일"}}),_c('p',{staticClass:"text-center mt-2 text-secondary"},[_vm._v("(준비중)")])])])
}]

export { render, staticRenderFns }